//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from "axios";
import api from "../api.js";
export default {
  data: () => ({
    dialogMsg: false,
    dialogChoiceItem: false,
    dialogBarcodeEdit: false,
    dialogWLocationEdit: false,
    dialogError: false,
    bImportarArticle: false,
    itemOne: {
      itemId: 0,
      itemCode: "",
      itemReference: "",
      itemBarcode: "",
      itemName: "",
      stock: 0.0,
      stockUpdate: 0.0,
      text: "",
    },
    itemList: [],
    item: undefined,
    itemestoc: undefined,
    barcodeOne: {
      docId: 0,
      itemId: 0,
      barcode: "",
      barcodeType: "",
    },
    barcodeList: [],
    barcode: undefined,
    wLocationList: [],
    wLocation: undefined,
    wLocationOne: {
      docId: 0,
      itemId: 0,
      barcode: "",
      barcodeType: "",
    },
    updateError: undefined,
    strErrortext: "",
  }),
  created() {},
  mounted() {
    this.setFocus("TextField_ItemCode");
  },
  methods: {
    goBack() {
      this.$router.push("/mainmenu");
    },
    goMenu() {
      this.$router.push("/mainmenu");
    },
    setFocus(nomCamp) {
      setTimeout(() => {
        if (document.getElementById(nomCamp) != null) {
          document.getElementById(nomCamp).focus();
        }
      }, 200);
    },
    newRecord() {
      this.itemOne.itemId = 0;
      this.itemOne.itemCode = "";
      this.itemOne.itemReference = "";
      this.itemOne.itemBarcode = "";
      this.itemOne.itemName = "";
      this.itemOne.stock = 0.0;
      this.itemOne.stockUpdate = 0.0;
      this.itemOne.text = "";
      this.barcodeList = [];
      this.wLocationList = [];
    },
    newBarcode() {
      if (
        parseInt(this.itemOne.itemId) != 0 &&
        this.itemOne.itemCode.length > 0
      ) {
        this.barcodeOne.docId = 0;
        this.barcodeOne.itemId = this.itemOne.itemId;
        this.barcodeOne.barcode = "";
        this.dialogBarcodeEdit = true;
        this.setFocus("TextField_Barcode");
      }
    },
    appendUpdateBarcode() {
      this.dialog = false;
      if (parseInt(this.barcodeOne.docId) == 0) {
        if (this.barcodeOne.barcode.length != 0) {
          axios
            .post(api.URL() + "/api/v1/garticlescbarres_append", {
              token: sessionStorage.getItem("Token"),
              itemId: this.barcodeOne.itemId,
              barcode: this.barcodeOne.barcode,
            })
            .then((response) => {
              this.updateError = response.data;
              if (this.updateError == "-1") {
                this.dialogError = true;
                this.strErrortext =
                  "Aquest codi de barres ja esta en un altre article";
              }
              this.articleCBarresGetById(this.itemOne.itemId);
            })
            .catch(function (error) {
              this.msgAlert(error.response, true);
            });
        }
      } else {
        axios
          .post(api.URL() + "/api/v1/garticlescbarres_update", {
            token: sessionStorage.getItem("Token"),
            docId: parseInt(this.barcodeOne.docId),
            barcode: this.barcodeOne.barcode,
          })
          .then((response) => {
            this.updateError = response.data;
            if (this.updateError == "-1") {
              this.dialogError = true;
              this.strErrortext =
                "Aquest codi de barres ja esta en un altre article";
            }
            this.articleCBarresGetById(this.itemOne.itemId);
          })
          .catch(function (error) {
            this.msgAlert(error.response, true);
          });
      }
    },
    editBarcode(docId) {
      if (docId != null && docId != 0) {
        axios
          .post(api.URL() + "/api/v1/garticlescbarres_getbyid", {
            token: sessionStorage.getItem("Token"),
            docId: parseInt(docId),
          })
          .then((response) => {
            this.barcode = response.data;
            this.barcodeOne.docId = this.barcode.docId;
            this.barcodeOne.itemId = this.barcode.itemId;
            this.barcodeOne.barcode = this.barcode.barcode;
            this.dialogBarcodeEdit = true;
            this.setFocus("TextField_Barcode");
          })
          .catch(function (error) {
            this.msgAlert(error.response);
          });
      }
    },
    deleteBarcode() {
      if (parseInt(this.barcodeOne.docId) != 0) {
        axios
          .post(api.URL() + "/api/v1/garticlescbarres_delete", {
            token: sessionStorage.getItem("Token"),
            docId: parseInt(this.barcodeOne.docId),
          })
          .then(() => {
            this.articleCBarresGetById(this.itemOne.itemId);
          })
          .catch(function (error) {
            this.msgAlert(error.response, true);
          });
      }
    },
    newWLocation() {
      if (
        parseInt(this.itemOne.itemId) != 0 &&
        this.itemOne.itemCode.length > 0
      ) {
        this.wLocationOne.docId = 0;
        this.wLocationOne.itemId = this.itemOne.itemId;
        this.wLocationOne.WLocation = "";
        this.dialogWLocationEdit = true;
        this.setFocus("TextField_WLocation");
      }
    },
    appendUpdateWLocation() {
      this.dialog = false;
      if (parseInt(this.wLocationOne.docId) == 0) {
        if (this.wLocationOne.WLocation.length != 0) {
          axios
            .post(api.URL() + "/api/v1/garticlesubicacions_append", {
              token: sessionStorage.getItem("Token"),
              itemId: this.wLocationOne.itemId,
              WLocation: this.wLocationOne.WLocation,
            })
            .then((response) => {
              this.updateError = response.data;
              if (this.updateError == "-1") {
                this.dialogError = true;
                this.strErrortext =
                  "Aquest codi de barres ja esta en un altre article";
              }
              this.articleCBarresGetById(this.itemOne.itemId);
            })
            .catch(function (error) {
              this.msgAlert(error.response, true);
            });
        }
      } else {
        axios
          .post(api.URL() + "/api/v1/garticlesubicacions_update", {
            token: sessionStorage.getItem("Token"),
            docId: parseInt(this.wLocationOne.docId),
            WLocation: this.wLocationOne.WLocation,
          })
          .then((response) => {
            this.updateError = response.data;
            if (this.updateError == "-1") {
              this.dialogError = true;
              this.strErrortext =
                "Aquest codi de barres ja esta en un altre article";
            }
            this.articleCBarresGetById(this.itemOne.itemId);
          })
          .catch(function (error) {
            this.msgAlert(error.response, true);
          });
      }
    },
    editWLocation(docId) {
      if (docId != null && docId != 0) {
        axios
          .post(api.URL() + "/api/v1/garticlesubicacions_getbyid", {
            token: sessionStorage.getItem("Token"),
            docId: parseInt(docId),
          })
          .then((response) => {
            this.barcode = response.data;
            this.wLocationOne.docId = this.barcode.docId;
            this.wLocationOne.itemId = this.barcode.itemId;
            this.wLocationOne.barcode = this.barcode.barcode;
            this.dialogWLocationEdit = true;
            this.setFocus("TextField_Barcode");
          })
          .catch(function (error) {
            this.msgAlert(error.response);
          });
      }
    },
    deleteWLocation() {
      if (parseInt(this.wLocationOne.docId) != 0) {
        axios
          .post(api.URL() + "/api/v1/garticlesubicacions_delete", {
            token: sessionStorage.getItem("Token"),
            docId: parseInt(this.wLocationOne.docId),
          })
          .then(() => {
            this.articleCBarresGetById(this.itemOne.itemId);
          })
          .catch(function (error) {
            this.msgAlert(error.response, true);
          });
      }
    },
    findItem(strValue) {
      var self = this;
      if (strValue.trim() != "") {
        axios
          .post(api.URL() + "/api/v1/garticles_find", {
            token: sessionStorage.getItem("Token"),
            text: strValue,
          })
          .then((response) => {
            self.itemList = response.data;

            if (self.itemList == null) {
              self.dialogMsg = true;
            }
            if (self.itemList != null && self.itemList.length == 1) {
              self.articleGetById(self.itemList[0].itemId);
            }
            if (self.itemList != null && self.itemList.length > 1) {
              self.dialogChoiceItem = true;
            }
          })
          .catch(function (error) {
            self.msgAlert(error.response, true);
          });
      } else {
        self.msgAlert("nothing to look for");
      }
    },
    articleGetById(ItemId) {
      if (ItemId != null && ItemId.trim != "") {
        axios
          .post(api.URL() + "/api/v1/garticles_getbyid", {
            token: sessionStorage.getItem("Token"),
            itemId: parseInt(ItemId),
          })
          .then((response) => {
            this.item = response.data;
            this.itemOne.itemId = this.item.itemId;
            this.itemOne.itemCode = this.item.itemCode;
            this.itemOne.itemName = this.item.itemName;
            this.articleGetStock(this.itemOne.itemId);
            this.articleCBarresGetById(this.itemOne.itemId);
          })
          .catch(function (error) {
            this.msgAlert(error.response, true);
          });
      }
    },
    articleGetStock(itemId) {
      if (itemId != null && itemId.trim != 0) {
        axios
          .post(api.URL() + "/api/v1/garticles_getstock", {
            token: sessionStorage.getItem("Token"),
            whseId: 1,
            itemId: parseInt(itemId),
            batchId: "",
          })
          .then((response) => {
            this.itemStock = response.data;
            this.itemOne.stock = this.itemStock.qty;
            this.itemOne.stockUpdate = 0.0;
          })
          .catch(function (error) {
            this.msgAlert(error.response, true);
          });
      }
    },
    articleUpdateStock(itemId, qty) {
      if (itemId != null && itemId.trim != 0) {
        axios
          .post(api.URL() + "/api/v1/simpleupdatestock", {
            token: sessionStorage.getItem("Token"),
            userId: parseInt(sessionStorage.getItem("UserId")),
            whseId: 1,
            itemId: parseInt(itemId),
            batch: "",
            opMode: "A",
            qty: parseFloat(qty),
          })
          .then((response) => {
            this.itemStock = response.data;
            this.itemOne.stock = this.itemStock.qty;
            this.itemOne.stockUpdate = 0.0;
          })
          .catch(function (error) {
            this.msgAlert(error.response, true);
          });
      }
    },
    articleCBarresGetById(itemId) {
      if (itemId != null && itemId.trim != 0) {
        axios
          .post(api.URL() + "/api/v1/garticlescbarres_getlist", {
            token: sessionStorage.getItem("Token"),
            itemId: parseInt(itemId),
          })
          .then((response) => {
            this.barcodeList = response.data;
          })
          .catch(function (error) {
            this.msgAlert(error.response, true);
          });
      }
    },
    msgAlert(missatge, close) {
      var onClose = () => {
        if (close && close == true) {
          this.$router.push("/");
        }
      };

      this.$alert.show({ message: missatge, onClose: onClose });
    },
  },
};
